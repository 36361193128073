@import "src/assets/scss/default/variables";
@import "src/assets/scss/default/typography";

.wrap {
  width: 100%;
  display: flex;
  gap: 20px;
  .form_item {
    width: 50%;
  }
  
  @media (max-width: 500px) {
    flex-direction: column;
    .form_item {
      width: 100%;
    }
  }

}



.mask_input {
  font-family: $body-font !important;
  border-radius: 10px;
  height: 40px;
  &:hover {
    border: 1px solid $theme-color;
  }

  &:focus {
    border: 1px solid $theme-color;
    box-shadow: 0px 0px 3px $theme-color;
  }

}

@media (max-width: 500px) {
  .input {
    min-width: 250px;
  }

  .mask_input {
    min-width: 250px;
  }
}

@media (max-width: 380px) {
  .input {
    min-width: 200px;
    max-width: 200px !important;
  }

  .mask_input {
    min-width: 200px;
    width: 100% !important;
  }
}