@import "default/variables";
@import "default/reset";
@import "default/typography";
@import "default/helper";
@import "default/mixins";
@import "default/animations";
@import "default/buttons";

@import "common/common";

@import "elements/header";
@import "elements/mobileMenu";
@import "elements/hero";
@import "elements/about";
@import "elements/contact";
@import "elements/contentBlock";
@import "elements/cta";
@import "elements/faq";
@import "elements/features";
@import "elements/footer";
@import "elements/funFact";
@import "elements/section";
@import "elements/services";


.color-theme {
    color: $theme-color;
}