@import "src/assets/scss/default/variables";

.form__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form__item {
  width: 100%;

  div {
    margin: 0;
  }
}


@media(max-width:$md) {
  .form__wrapper {
    flex-direction: column;
  }
}