/* ----------------------------------
  Service Section Styles
 ------------------------------------ */
.services-1 {
    padding-bottom: 147px;

    @media (max-width: 991px) {
        padding-bottom: 100px;
    }

    @media (max-width: 991px) {
        padding-bottom: 100px;
    }
}

.single-service-item {
    &.service-1 {
        margin-top: 60px;
        padding: 20px 20px 40px 20px;
        border-radius: 10px;
        position: relative;
        z-index: 1;
        background: #fff;
        text-align: left;


        .service-bg {
            overflow: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 1;
            left: 0;
            top: 0;
            content: "";
            z-index: -1;
            border-radius: 10px;
            transition: all 0.3s ease-in-out;

            &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.8;
                left: 0;
                top: 0;
                content: "";
                background-color: #000;
            }
        }

        .icon {
            width: 80px;
            height: 80px;
            margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            color: #fff;
            font-size: 28px;
            background-color: $theme-color;
            line-height: 120px;
            transition: all 0.3s ease-in-out;
            text-align: center;
            color: $theme-color;
            background-color: #fff;

        }

        h3 {
            font-size: 26px;
            letter-spacing: -1px;
            transition: all 0.3s ease-in-out;
            color: #fff;

            @media (max-width: 767px) {
                font-size: 22px;
            }
        }

        p {
            padding: 20px 0;
            font-size: 12px;
            line-height: 14px;
        }

        .link {
            margin: 0 auto;
            text-align: center;
        }

        a {
            display: inline;
            border-radius: 10px;
            background: $theme-color;
            padding: 19px 25px;
            line-height: 1;
            font-weight: 700;
            text-transform: capitalize;
            color: #000;
            position: absolute;
            text-align: center;

            width: 60%;
            bottom: 0;
            transform: translate(-50%, 50%);

            @media (max-width: 767px) {
                width: 50%;
                padding: 18px 24px;
                font-weight: 500;
            }

            span {
                margin-right: 15px;
            }

        }
    }
}


.services-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 40px;
    margin: 0 40px;

    @media (max-width: 1320px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        margin: 0 30px;
    }

    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        margin: 0 15px;
    }

    .single-service-box {
        position: relative;
        z-index: 0;
        height: 420px;
        overflow: hidden;

        @media (max-width: 1199px) {
            height: 350px;
        }

        &::before {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            content: "";
            opacity: 0.6;
            background-color: #001248;
            z-index: -1;
        }

        &:hover,
        &.active {
            .hover-flip-content {
                left: 0;
                opacity: 1;
                visibility: visible;
            }
        }

        .service-title {
            display: grid;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0px 30px;
            text-align: center;

            h2 {
                color: #fff;
            }
        }

        .hover-flip-content {
            position: absolute;
            left: -200px;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: #fff;
            padding: 50px;
            box-shadow: $box-shadow;
            opacity: 0;
            visibility: hidden;
            transition: all 0.4s ease-in-out;
            z-index: 1;

            @media (max-width: 1199px) {
                padding: 30px;
            }

            .icon {
                margin-bottom: 20px;

                h1 {
                    font-size: 100px;
                    line-height: 1;
                    color: #001248;
                    -webkit-text-fill-color: white;
                    /* Will override color (regardless of order) */
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: #001248;
                }
            }

            h2 {
                font-size: 28px;
                line-height: 120%;
                margin-bottom: 20px;
            }
        }
    }
}

.process-setps {
    overflow: hidden;

    .single-process-item {
        overflow: hidden;
        padding-right: 80px;
        margin-top: 20px;
        display: flex;
        align-items: center;

        @media (max-width: 585px) {
            padding-right: 0;
            margin-top: 30px;
            display: block;
        }

        &:nth-child(1) {
            .setp-number {
                margin-right: 40px;
            }
        }

        .setp-number {
            float: left;
            margin-right: 25px;

            @media (max-width: 585px) {
                margin-right: 0;
                float: none;
                margin-bottom: 20px;
            }

            h1 {
                font-size: 100px;
                line-height: 1;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: $theme-color;
                color: transparent;

                @media (max-width: 991px) {
                    font-size: 80px;
                }

                @media (max-width: 767px) {
                    font-size: 60px;
                }
            }
        }

        .content {
            overflow: auto;

            h3 {
                margin-bottom: 5px;
                margin-top: -3px;

                @media (max-width: 767px) {
                    font-size: 22px;
                }
            }

            p {}
        }
    }
}


.service-details-wrapper {
    h2 {
        font-size: 32px;

        @media (max-width: 767px) {
            font-size: 28px;
        }

        @media (max-width: 500px) {
            font-size: 26px;
            line-height: 140%;
        }
    }

    .owl-theme {
        .owl-nav {
            div {
                left: 0;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
                color: $white;
                background-color: $theme-color;
                margin: 0;
                padding: 0;
                width: 60px;
                height: 60px;
                text-align: center;
                line-height: 60px;
                font-size: 20px;
                display: inline-block;
                border-radius: 0px;

                &:hover {
                    background-color: $second-color;
                    color: #fff;
                }

                &.owl-next {
                    left: auto;
                    right: 0;
                }
            }
        }

        .owl-dots {
            position: absolute;
            margin: 0;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;

            div {
                &.active {
                    span {
                        background: $theme-color-3;
                    }
                }

                span {
                    background: #fff;

                    &:hover {
                        background-color: $theme-color-3;
                        color: #fff;
                    }
                }
            }
        }
    }

    .service-details-content-wrapper {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            padding-bottom: 20px;
        }

        img {
            margin-bottom: 20px;
        }

        blockquote,
        .wp-block-quote {
            background-color: $second-color;
            color: #fff;
            font-size: 26px;
            line-height: 1.4;
            font-family: $heading-font;
            font-weight: 400;
            padding: 60px;
            text-align: left;
            margin: 40px 0px;
            position: relative;
            z-index: 1;

            @media (max-width: 991px) {
                padding: 30px;
                font-size: 22px;
                line-height: 1.5;
                margin: 20px 0px;
            }

            @media (max-width: 767px) {
                padding: 30px 15px;
                font-size: 18px;
            }

            &::before {
                right: 30px;
                font-size: 110px;
                line-height: 1;
                font-family: $fa;
                position: absolute;
                content: "\f10d";
                bottom: -20px;
                color: #fff;
                z-index: -1;
                opacity: 0.1;
                font-weight: 900;
            }
        }
    }

    .faq-accordion {
        padding-top: 10px;

        .card {
            background-color: $theme-color;
        }

        .card-header {
            a {
                &:hover {
                    color: $white;
                }

                &::after {
                    color: $white;
                }
            }
        }
    }
}

.service-details-sidebar {
    position: sticky;
    top: 10px;

    .single-service-sidebar {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        .sidebar-title {
            margin-bottom: 20px;

            h3 {
                text-transform: capitalize;
                margin-top: -7px;
            }
        }

        ul {
            li {
                position: relative;

                a {
                    position: relative;
                    display: block;
                    background-color: $theme-color;
                    color: #000;
                    text-align: center;
                    padding: 15px 20px;
                    text-transform: capitalize;
                    font-weight: 500;
                    margin-top: 10px;

                    &:hover {
                        background-color: $second-color;
                        color: #fff;
                    }
                }
            }
        }

        .brochures-download {
            a {
                display: block;
                margin-top: 10px;
                text-align: center;
                font-weight: 500;
                text-transform: capitalize;

                i {
                    margin: 0;
                    margin-right: 10px;
                    display: inline-block;
                }

                &:first-child {
                    background-color: #000;
                    border-color: #000;
                    color: #fff;
                }

                &:last-child {
                    background-color: $second-color;
                    border-color: $second-color;
                    color: #fff;
                }
            }
        }

        .contact-us {
            .single-contact-info {
                overflow: hidden;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                .icon {
                    float: left;
                    margin-right: 15px;
                    overflow: hidden;
                }

                .contact-info {
                    overflow: hidden;

                    span {}

                    p {
                        font-weight: 600;
                        color: #001248;
                        font-size: 17px;
                    }
                }
            }
        }
    }
}

.single-service-card {
    padding: 15px;
    background-color: #fff;
    position: relative;
    box-shadow: 0px 30px 60px 0px rgba(52, 52, 52, 0.04);
    margin-top: 30px;

    *{
        transition: var(--default-transiton);
    }

    .card-thumb {
        height: 200px;
        position: relative;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &:hover{
        .card-thumb img{
            scale: 1.2;
        }
    }

    .content {
        padding: 30px 35px;
        background-color: #f7f7f7;
        position: relative;

        @media (max-width: 600px) {
            padding: 30px;
        }

        .case-cat {
            position: absolute;
            right: 0;
            bottom: 100%;

            a {
                display: inline-block;
                padding: 20px;
                line-height: 1;
                font-size: 24px;
                background-color: $theme-color;
                position: relative;

                &:hover {
                    color: #fff;
                    background: $theme-color-2;
                }
            }
        }

        h3 {
            font-size: 22px;
            margin-bottom: 5px;
            text-transform: capitalize;

            &:hover {
                a {
                    color: $theme-color;
                }
            }
        }

        .read-btn {
            font-size: 16px;
            line-height: 1;
            text-transform: capitalize;
            margin-top: 20px;
            display: block;

            &:hover {
                color: $theme-color-3;
                padding-left: 5px;
            }

            i {
                margin-left: 5px;
            }
        }
    }
}