// All Css Here
@import "bootstrap/dist/css/bootstrap.min.css";
@import "./assets/scss/style.scss";


:root {
    --default-transiton: all 0.4s ease;
}

.page {
    transition: var(--default-transiton);
}

.page-enter {
    opacity: 0;
}

.page-enter-active {
    opacity: 1;
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}


.video-controls-disable::-webkit-media-controls {
    display: none !important;
}